import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  let content;

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'The official portal of the uminek.ooo' },
              { name: 'keywords', content: 'uminek.ooo, うみねこ, Vセーラー, バーチャルユーチューバー, VTuber, バーチャルヨットセーラー, VSailor, VirtualYachtSailor, VirtualSailor' },
            ]}
          >
	  <html lang="ja" />
	  <script src="https://sdk.form.run/js/v2/formrun.js"></script>
	  <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
	  <script>{`
	  (adsbygoogle = window.adsbygoogle || []).push({
google_ad_client: "ca-pub-6472136014553171",
enable_page_level_ads: true
});
	  `}</script>
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
